
























import { Component, Vue } from 'vue-property-decorator'

@Component
export default class EnvironmentBanner extends Vue {
  get isLocal () {
    return process.env.VUE_APP_API_URL?.includes('local')
  }

  get environment () {
    return this.isLocal ? 'local' : process.env.VUE_APP_ENVIRONMENT
  }

  get bannerColor () {
    switch (this.environment.toLowerCase()) {
      case 'local':
        return 'white'
      case 'development':
        return 'green'
      case 'staging':
        return 'orange'
      default:
        return '#d0442c'
    }
  }

  // don't show in prod, for now
  get isDisplayed () {
    return this.environment.toLowerCase() !== 'production'
  }
}
