






















































import Vue from 'vue'
import NavDrawer from '@/components/shared/Drawer.vue'
import EnvironmentBanner from '@/components/shared/EnvironmentBanner.vue'
import AppBar from './components/shared/AppBar.vue'
import Component from 'vue-class-component'
import { AuthService } from './services/auth-service'

@Component({
  components: {
    AppBar,
    EnvironmentBanner,
    NavDrawer
  }
})
export default class App extends Vue {
  private authService = AuthService.getInstance()
  userName: string | undefined = ''
  public mounted () {
    this.authService.isAuth$.subscribe(auth => {
      if (auth) {
        this.userName = this.authService.name
      } else {
        this.userName = ''
      }
    })
    this.authService.$user.subscribe(email => {
      if (email) {
        this.userName = email
      }
    })
  }

  public signout () {
    this.authService.signout()
  }

  public signin () {
    this.authService.login()
  }

  public getBrowser () {
    if (window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      return 'firefox'
    } else if (window.navigator.userAgent.toLowerCase().indexOf('chrome') > -1 && window.navigator.userAgent.toLowerCase().indexOf('edg') === -1) {
      return 'chrome'
    } else if (window.navigator.userAgent.toLowerCase().indexOf('edg') > -1) {
      return 'edge'
    } else if (window.navigator.userAgent.toLowerCase().indexOf('safari') > -1) {
      return 'safari'
    } else {
      return 'other'
    }
  }
}

